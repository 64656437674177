import { render, staticRenderFns } from "./forgotPassword.vue?vue&type=template&id=60fab98b&scoped=true&"
import script from "./forgotPassword.vue?vue&type=script&lang=js&"
export * from "./forgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./forgotPassword.vue?vue&type=style&index=0&id=60fab98b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fab98b",
  null
  
)

export default component.exports