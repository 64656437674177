<template>
	<div class="frameCon">
		<div class="registerCon">
			<div class="registerSuCon">
				<h2><!-- 找回密码 -->{{$t("register.retrieve_password")}}</h2>
				<div class="forgotPassword" v-loading="loading" :element-loading-text="$t('tips.Submitting')">
					<el-steps :active="active" finish-status="success" align-center>
						<el-step :title="$t('register.authentication')"></el-step>
						<el-step :title="$t('register.reset_passwords')"></el-step>
						<el-step :title="$t('register.complete')"></el-step>
					</el-steps>
					<div class="forgotPasswordCon">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
							<div v-if="active===0">
								<el-form-item :label="$t('i18nn_94a8da065245abe4')" prop="reginType">
									<el-radio-group v-model="ruleForm.reginType" @change="changeRegType">
										<el-radio :label="'0'">{{$t('i18nn_e82c9fff83ec2d91')}}</el-radio>
										<el-radio :label="'1'">{{$t('i18nn_b2d4c7fffe79258e')}}</el-radio>
									</el-radio-group>
								</el-form-item>

								<div v-if="'1'==ruleForm.reginType">
									<el-form-item :label="$t('i18nn_b2d4c7fffe79258e')" prop="email">
										<el-input ref="email" :placeholder="$t('register.Please_Enter')+' '+$t('i18nn_b2d4c7fffe79258e')" v-model="ruleForm.email"
											name="hyUserEmail">
										</el-input>
									</el-form-item>
								</div>

								<div v-else>
									<el-form-item :label="$t('register.Mobile_phone')" prop="phoneNumber">

										<el-input ref="phoneNumber" :placeholder="$t('register.placeholder_phone')"
											v-model="ruleForm.phoneNumber" name="hyUserCode" maxlength="11" class="input-with-select">
											<div slot="prepend">
												<HyAreaCodeSel :areaCode="ruleForm.areaCode" :areaCodeText="$t('register.China')"
													@selData="selMobileData"></HyAreaCodeSel>
											</div>
										</el-input>

									</el-form-item>
								</div>
								<el-form-item :label="$t('register.Verification_Code')" prop="">
									<el-input v-model="ruleForm.verificationCode" :placeholder="$t('register.placeholder_Code')"
										style="width: 150px;"></el-input>
									<!-- <el-button type="warning" style="width: 150px;" @click="phoneCodeData()" :disabled="''==ruleForm.phoneNumber">{{$t('i18nn_ace46c7b30cb9a6e')}}</el-button> -->
									<el-button type="warning" style="width: 150px;" @click="verCodeAction()"
										:disabled="isGetVerifCode.disabled">
										<span v-if="!isGetVerifCode.count">{{$t('register.Get_Code')}}</span>
										<span v-if="isGetVerifCode.count">{{isGetVerifCode.count}}{{$t('register.seconds')}}</span>
									</el-button>
								</el-form-item>
								<el-button type="primary" class="nextFp" @click="verEmail()"
									:disabled="!((''!=ruleForm.email || ''!=ruleForm.phoneNumber)&&''!=ruleForm.verificationCode)">{{$t("register.next_step")}}</el-button>
								<!-- </el-form> -->
							</div>

							<div v-else-if="active===1">
								<!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"> -->
								<el-form-item :label="$t('register.new_password')" prop="password">
									<el-input type="password" v-model="ruleForm.password" :placeholder="$t('register.Please_Enter')"
										show-password></el-input>
								</el-form-item>
								<el-form-item :label="$t('register.confirm_password')" prop="rePassword">
									<el-input type="password" v-model="ruleForm.rePassword" :placeholder="$t('register.Please_Enter')"
										show-password></el-input>
									<!-- <el-button type="warning" style="width: 150px;" @click="submitForm('ruleForm')">{{$t('i18nn_ace46c7b30cb9a6e')}}</el-button> -->
								</el-form-item>
								<el-button type="primary" class="nextFp" @click="modifyPassAction"
									:disabled="''==ruleForm.rePassword || ''==ruleForm.password">{{$t("register.next_step")}}</el-button>
							</div>

							<div v-else-if="active===2">
								<div class="registerSu">
									<h3><i
											class="el-icon-circle-check"></i><span><!-- 恭喜您，找回密码成功！ -->{{$t("register.repass_success")}}</span>
									</h3>
									<p><!-- 请牢记您的新密码 -->{{$t("register.repass_tip1")}}，{{isTimeOutCode.count}}
										<!-- 秒后跳转到登录页 -->{{$t("register.repass_tip2")}}</p>
									<p><el-button type="text" @click="toLogin"><!-- 去登录 -->{{$t("register.To_log_in")}}</el-button></p>
								</div>
							</div>
							<div v-else>
								<!-- 找回失败，请返回重试！ -->{{$t("register.repass_fial")}}
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
	// import crypto from "crypto"//在使用的页面引入加密插件
	import HyAreaCodeSel from '../Common/HyAreaCodeSel.vue'
	export default {
		// name: "forgotPassword",
		components: {
			HyAreaCodeSel
		},
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-找回密码', // set a title
		//   // meta: [{                 // set meta
		//   //   name: 'description',
		//   //   content: '互易天下-厂家共享平台-找回密码'
		//   // }],
		// },
		data() {
			return {
				active: 0,
				// userInfo: this.$store.getters.getUserInfo,
				// role: this.$store.getters.getRole,
				loading: false,
				// loading_phone: false,
				isGetVerifCode: {
					disabled: false,
					count: '',
					timer: null,

				},
				isTimeOutCode: {
					// disabled: false,
					count: '5',
					timer: null,
				},
				// areaCodeList: this.$store.state.areaCodeList,
				ruleForm: {
					"reginType": '0', //1--邮箱，'0'--手机号
					"email": "",

					areaCode: '86',
					phoneNumber: "",
					verificationCode: "",
					password: "",
					rePassword: "",

				},
				rules: {
					// userName: [
					//   { required: true, message: this.$t('i18nn_04152d1213c1a758'), trigger: 'blur' },
					//   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					// ],
					reginType: [{
						required: true,
						message: (this.$t('register.Please_Enter') + ' ' + this.$t('i18nn_94a8da065245abe4')),
						trigger: 'change'
					}, ],
					email: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('i18nn_b2d4c7fffe79258e')),
							trigger: 'blur'
						},
						{
							pattern: this.$Validate.REGEX_EMAIL,
							message: this.$t('i18nn_474da87fdb795187')
						}
					],
					password: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('register.Password')),
							trigger: 'blur'
						},
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						{
							pattern: this.$Validate.REGEX_PASSWORD,
							message: this.$t('register.placeholder_must_pwd')
						}
					],
					rePassword: [{
							required: true,
							message: (this.$t('register.Please_Enter') + ' ' + this.$t('register.Duplicate_password')),
							trigger: 'blur'
						},
						{
							pattern: this.$Validate.REGEX_PASSWORD,
							message: this.$t('register.placeholder_must_pwd')
						}
						// { pattern: this.rules.password, message: this.$t('i18nn_b978bd71e959b620')}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],
					phoneNumber: [{
							required: true,
							message: (this.$t('register.placeholder_phone')),
							trigger: 'blur'
						},
						{
							pattern: this.$Validate.REGEX_PHONE_International,
							message: this.$t('register.placeholder_must_phone')
						}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],
					// verificationCode: [
					//   { required: true, message: this.$t('i18nn_f1ee1d97e869e447'), trigger: 'blur' },
					//   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					// ],
					// userType: [
					//   { required: true, message: this.$t('i18nn_eae360b6837f15e9'), trigger: 'change' }
					// ]
				}
			}
		},
		created() {
			// console.log("created");
			// console.log(this);
			this.$parent.loginTitle = this.$t("register.retrieve_password");
			//手机区域代码
			// this.$store.dispatch('UPDATE_PHONE_AREACODE_DATA').then(res => {
			//   console.log(this.$t('i18nn_09a6f3a7fdcdaae1'), res);
			//   this.areaCodeList = res;
			// })
			// .catch(res => {
			//   console.log(this.$t('i18nn_6b0ab3e65c3be5f6'), res);
			//   this.$message.warning(this.$t('i18nn_6b0ab3e65c3be5f6'));
			// });
		},
		mounted() {
			// console.log("mounted");
			// this.init();
		},
		methods: {
			// submitForm(formName) {
			// 	// this.$router.replace({name:'registerSuccess'});
			// },
			//选择区域代码
			selMobileData(item) {
				// this.areaCodeShow = "";
				// this.areaCodeTextShow = "";
				this.ruleForm.areaCode = item.codeText; //手机国际区号
				// this.phoneNumber= item.code;
			},
			// next() {
			//    if (this.active++ > 2) {
			//    	this.active = 0;
			//    }

			// },
			//发送验证码成功
			codeSendSuccess() {
				this.$alert(this.$t('i18nn_4733de26e1c2e985') + ',' + this.$t('i18nn_cdf92fd92be6533d'), this.$t('tips.tipsTitle'), {
					type: 'success',
					// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
				// this.$message.success(this.$t('i18nn_4733de26e1c2e985') + ',' + this.$t('i18nn_cdf92fd92be6533d'));
				this.isGetVerifCode.disabled = true;
				// let _this = this;
				setTimeout(() => {
					this.isGetVerifCode.disabled = false;
					// console.log(_this.isGetVerifCode.disabled);
				}, 1000 * 60);
				this.getCode();
			},
			//倒计时
			getCode() {
				const TIME_COUNT = 60;
				// let _this = this;
				if (!this.isGetVerifCode.timer) {
					this.isGetVerifCode.count = TIME_COUNT;
					// this.isGetVerifCode.disabled = false;
					this.isGetVerifCode.timer = setInterval(() => {
						if (this.isGetVerifCode.count > 0 && this.isGetVerifCode.count <= TIME_COUNT) {
							this.isGetVerifCode.count--;
						} else {
							this.isGetVerifCode.disabled = false;
							clearInterval(this.isGetVerifCode.timer);
							this.isGetVerifCode.timer = null;
						}
						console.log('setInterval,getCode');
					}, 1000)
				}
			},
			//倒计时五秒到登录页
			getCodeTimeout() {
				const TIME_COUNT = 5;
				// let _this = this;
				if (!this.isTimeOutCode.timer) {
					this.isTimeOutCode.count = TIME_COUNT;
					// this.isGetVerifCode.disabled = false;
					this.isTimeOutCode.timer = setInterval(() => {
						if (this.isTimeOutCode.count > 0 && this.isTimeOutCode.count <= TIME_COUNT) {
							this.isTimeOutCode.count--;
						} else {
							// _this.isTimeOutCode.disabled = false;
							clearInterval(this.isTimeOutCode.timer);
							this.isTimeOutCode.timer = null;
						}
					}, 1000)
				}
			},
			//倒计时五秒到登录页
			getCodeTimeoutSuccess() {
				// this.$alert('验证码已发送，有效时间五分钟！', this.$t('tips.tipsTitle'), {
				//        type:'success',
				//        //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				//      });
				// this.isTimeOutCode.disabled = true;
				// let _this = this;
				setTimeout(() => {
					this.toLogin();
					// _this.isTimeOutCode.disabled = false;
				}, 1000 * 5);
				this.getCodeTimeout();
			},
			//去登陆
			toLogin() {
				// let _this = this;
				clearInterval(this.isGetVerifCode.timer);
				clearInterval(this.isTimeOutCode.timer);
				this.isTimeOutCode.timer = null;
				this.$router.replace({
					name: 'login'
				});
				console.log('setInterval,toLogin');
			},

			changeRegType() {
				console.log('changeRegType');
				try {
					this.$refs.ruleForm.validateField('phoneNumber');
					this.$refs.ruleForm.validateField('email');
				} catch (e) {
					console.log(e);
				}
				// this.ruleForm.phoneNumber = "";
				// this.ruleForm.email = "";
				this.ruleForm.verificationCode = "";
			},
			//验证码
			verCodeAction() {
				if ('1' == this.ruleForm.reginType) { //邮箱
					this.emailCodeData();
				} else { //手机号
					this.phoneCodeData();
				}
			},
			//邮箱获得验证码
			emailCodeData() {
				if (!(this.$Validate.REGEX_EMAIL.test(this.ruleForm.email))) {
					this.$alert(this.$t('i18nn_2fca45d67fdcae33'), this.$t('tips.tipsTitle'), {
						type: "warning",
					});
					return false;
				}
				this.loading = true;
				this.$http.post(this.$urlConfig.ServiceEmailCode, {
						email: this.ruleForm.email,
						codeType: '1',
					})
					.then(({
						data
					}) => {
						this.loading = false;
						if (200 == data.code) {
							this.codeSendSuccess();
						} else {
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: 'warning',
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_0f00eaea829fd543'));
						this.loading = false;
						this.$alert(this.$t("tips.requestErrorData"), this.$t('tips.tipsTitle'), {
							type: 'warning',
						});
					});
			},

			//手机获得验证码
			phoneCodeData() {
				console.log(this.ruleForm.phoneNumber);
				// if(!this.ruleForm.phoneNumber){
				if (!(this.$Validate.REGEX_PHONE_International.test(this.ruleForm.phoneNumber))) {
					this.$alert(this.$t('register.placeholder_phone'), this.$t('tips.tipsTitle'), {
						// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
					return false;
				}
				// let _this = this;
				this.loading = true;
				this.$http.post(this.$urlConfig.ServicePhoneCode, {
						phoneNumber: this.ruleForm.phoneNumber,
						areaCode: this.ruleForm.areaCode,
						codeType: '1',
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_a034ab2810999eb7'));
						console.log(data);

						this.loading = false;

						if (200 == data.code) {

							// this.$alert('验证码已发送，有效时间五分钟！', this.$t('tips.tipsTitle'), {
							//   type:'success',
							//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							// });
							this.codeSendSuccess();
						} else {
							// if(!data.msg){
							//   data.msg = this.$t('i18nn_0f00eaea829fd543');
							// }
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: 'warning',
								// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						}

					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_0f00eaea829fd543'));
						this.loading = false;
						this.$alert(this.$t("tips.requestErrorData"), this.$t('tips.tipsTitle'), {
							type: 'warning',
							// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//验证邮箱
			verEmail() {
				clearInterval(this.isGetVerifCode.timer);
				// let that = this;
				// console.log(this.$t('i18nn_7ed6be9d24f606ec'));
				this.loading = true;
				this.$http.post(this.$urlConfig.verifiedPhoneNumber, {
						areaCode: this.ruleForm.areaCode,
						phoneNumber: this.ruleForm.phoneNumber,
						verificationCode: this.ruleForm.verificationCode,
						email: this.ruleForm.email,
						reginType: this.ruleForm.reginType,
					}).then(({
						data
					}) => {
						// console.log(this.$t('i18nn_2e3c70a1029b0f4e'));
						// console.log(data);

						this.loading = false;

						if (200 == data.code) {
							this.active = 1;
						} else {
							// this.$alert('验证码存在错误！',this.$t('tips.tipsTitle'));
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: "warning",
								// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});

						}

					})
					.catch(function(error) {
						console.log(error);
						this.loading = false;
						this.$message.error(this.$t("tips.requestErrorData"));

					});



			},
			//密码前端加密
			// md5Pass(pass,salt){
			//   let md5=crypto.createHash("md5");
			//   md5.update(pass+''+salt);
			//   let passWord=md5.digest('hex');
			//   console.log('passWord',passWord);
			//   return passWord+'hy818';
			// },
			//修改密码请求
			modifyPassAction() {
				// console.log(this);
				//密码格式限制
				if (!(this.$Validate.REGEX_PASSWORD.test(this.ruleForm.password))) {
					this.$alert(this.$t('register.placeholder_must_pwd'), this.$t('tips.tipsTitle'), {
						// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
					return false;
				}
				// let that = this;/
				console.log(this.$t('i18nn_b2474240b4563afa'));
				this.loading = false;
				//比对密码是否一致
				if (this.ruleForm.rePassword != this.ruleForm.password) {
					this.$alert(this.$t('register.ver_re_pwd'), this.$t('tips.tipsTitle'));
				} else {

					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							this.postData();
						} else {
							console.log('error submit!!');
							this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
								type: "warning",
								// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							return false;
						}
					});
				}

			},
			postData() {
				this.loading = true;
				// let that = this;
				this.$http.post(this.$urlConfig.resetPassword, {
						areaCode: this.ruleForm.areaCode,
						phoneNumber: this.ruleForm.phoneNumber,
						password: this.ruleForm.password,
						email: this.ruleForm.email,
						reginType: this.ruleForm.reginType,
					}).then(({
						data
					}) => {
						// console.log(this.$t('i18nn_86f62ce872f87de1'));
						// console.log(data);

						this.loading = false;
						if (200 == data.code) {
							this.active = 2;
							this.getCodeTimeoutSuccess();
						} else {
							console.log(data);
							this.$alert(data.msg ? data.msg : this.$t("tips.errorData"), this.$t('tips.tipsTitle'), {
								type: "warning",
							});
						}
					})
					.catch(function(error) {
						console.log(error);
						this.loading = false;
						this.$message.error(this.$t("tips.requestErrorData"));

					});
			}
		},
	}
</script>
<style lang='less' scoped>
	// @import url(../../assets/css/module/login.less);
</style>